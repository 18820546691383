export const langs = {
  password_change: {
    // NOVAS
    description: "Preencha os campos a baixo para alterar sua senha",
    "back-to-store": "Voltar para a loja",
    "fail-to-change-password":
      "Houve um erro ao trocar sua senha. Tente novamente mais tarde",
    "success-to-change-password":
      "Nova senha salva com sucesso! Você será redirecionado para loja.",
    // JÁ EXISTENTES
    title: "Alterar senha",
    "save-password": "Salvar nova senha",
    "must-contain": "Sua senha precisa conter:",
    "min-characters": "Mínimo de 8 caracteres",
    "min-number": "Pelo menos 1 número",
    "min-letter": "Pelo menos 1 letra",
    "new-password": "Nova senha",
    "confirm-password": "Confirmar nova senha",
    errors: {
      "confirm-password": "Para salvar, confirme a senha",
      "create-password": "Para salvar, crie uma nova senha",
      equal: "Senhas não são iguais",
      length: "A senha deve ter no mínimo 8 caracteres.",
      requirements: "Senha não atende aos requisitos",
    },
  },
};
