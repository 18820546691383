<script>
import { langs } from "./langs";
import icon from "@/mixins/icon";
import useVuelidate from "@vuelidate/core";
import { mapState, mapActions } from "vuex";
import AppSvg from "@/features/general/Svg";
import AppIcon from "@/features/general/Icons";
import AppAlert from "@/features/bootstrap/Alert";
import featuresToggles from "@/mixins/featuresToggles";
import { required, sameAs } from "@vuelidate/validators";
import AppInputGroup from "@/features/general/InputGroup";
import { SvgIcon } from "@/domains/Orders/components/order-total/icon-resolver/icon";
import {
  isValidLength,
  containsLetter,
  containsNumber,
} from "@/domains/Customer/components/edit/validators/password";

export default {
  name: "AppMailPasswordChange",
  mixins: [icon, featuresToggles],
  data() {
    return {
      identification: "",
      password: "",
      confirmPassword: "",
      showPassword: false,
      showConfirmation: false,
      customerId: "",
      isSaving: false,
      loadingPage: "app__loading",
      icons: [
        new SvgIcon("circle"),
        new SvgIcon("times"),
        new SvgIcon("check"),
      ],
    };
  },

  components: {
    AppInputGroup,
    AppIcon,
    AppSvg,
    AppAlert,
  },

  setup() {
    /**
     * Objeto que mantém o estado das validações do vuelidate
     */
    return { v$: useVuelidate({ $autoDirty: true }) };
  },

  methods: {
    ...mapActions("Customer", [
      "clearBackendErrors",
      "setBackendErrors",
      "clearMessages",
      "setMessage",
    ]),

    /**
     * Verifica se se o token de acesso é valido
     * @param {string} storeId ID da loja
     * @return {undefined}
     */
    async validateAccessCode(storeId) {
      const path = `/customer/validate-access-code/${this.$route.params.access_code}/${storeId}`;

      try {
        const { data } = await this.$http.get(path);

        if (!data.valid) {
          throw new Error();
        }

        this.setLoadingPage(false);
      } catch {
        this.redirectToLogin();
      }
    },

    /**
     * Envia o formulário com os dados para a troca de senha
     * @return {undefined}
     */
    async onSubmit() {
      this.clearMessages();
      this.setLoadingPage("app__loading");

      const path = `customer/${this.$route.params.access_code}/mail-password-change`;
      const body = {
        password: this.password,
        confirmation: this.confirmPassword,
        storeId: this.store_info.store_id,
      };

      try {
        const { data } = await this.$http.put(path, body);

        if (data.errors) {
          throw new Error();
        }

        this.showLoginSuccessAlert();
        setTimeout(() => this.redirectToLogin(), 4000);
      } catch {
        this.showLoginFailedAlert();
      }
    },

    /**
     * Exibe o alert com mensagem de erro no login
     * @return {undefined}
     */
    showLoginFailedAlert() {
      this.setLoadingPage(false);
      this.setMessage({
        variant: "danger",
        text: `${this.langs.password_change["fail-to-change-password"]}`,
      });
    },

    /**
     * Exibe o modal com mensagem de sucesso no login
     * @return {undefined}
     */
    showLoginSuccessAlert() {
      this.setLoadingPage(false);
      this.isSaving = true;
      this.setMessage({
        variant: "success",
        text: `${this.langs.password_change["success-to-change-password"]}`,
      });
    },

    /**
     * Altera a visibilidade da senha
     * @return {undefined}
     */
    toggleVisibility() {
      this.showPassword = !this.showPassword;
    },

    /**
     * Altera a visibilidade da confirmação de senha
     * @return {undefined}
     */
    toggleVisibilityConfirmation() {
      this.showConfirmation = !this.showConfirmation;
    },

    /**
     * Redireciona para a loja
     * @return {undefined}
     */
    redirectToStore() {
      window.location.replace(window.location.origin);
    },

    /**
     * Redireciona para a loja
     * @return {undefined}
     */
    redirectToLogin() {
      window.location.replace(
        `${window.location.origin}/loja/login_layout.php?origem=central`
      );
    },

    /**
     * Atribui o valor pasado por parametro ao loadingPage
     * @return {undefined}
     */
    setLoadingPage(value) {
      this.loadingPage = value;
    },

    /**
     * Retorna o state de acordo com as opções do validador
     * https://monterail.github.io/vuelidate/
     *
     * @param {object} $dirty Se o input já sofreu interação do usuário
     * @param {object} $error Se o input é inválido e já sofreu interação do usuário, ou
     * seja, quando [$invalid] e [$dirty] são true.
     * @param {object} $invalid Se o input não "passa" em alguma validação
     * @param {object} $model Valor do input
     * @param {string} validationMessage Mensagem de validação customizada
     * @returns {boolean|string}
     */
    getValidatorState(
      { $dirty, $error, $invalid, $model } = {},
      validationMessage
    ) {
      const wasTouched = $dirty;
      const hasNoErrors = !$error;

      if (validationMessage && validationMessage.length) {
        return "invalid";
      }

      if (wasTouched) {
        return hasNoErrors;
      }

      const isAnyValidationInvalid = $invalid;
      const isEmpty = !$model;

      const isNeutral = hasNoErrors && (isAnyValidationInvalid || isEmpty);
      return isNeutral ? "initial" : !isAnyValidationInvalid;
    },
  },

  computed: {
    ...mapState({
      store_info: (state) => state.store_info.list,
    }),
    ...mapState("Customer", {
      backendErrors: (state) => state.customer.backendErrors,
      messages: (state) => state.customer.messages,
    }),

    langs() {
      return langs;
    },

    /**
     * Retorna a classe utilizada no botão de enviar formulário
     * @return {string}
     */
    buttonFormClass() {
      const isDisabled = this.isButtonDisabled ? "--disabled" : "";
      return `app__customer-mail-password-change__form__button${isDisabled}`;
    },

    /**
     * Valida se o botão deve ser desativado ou não
     * @return {bool}
     */
    isButtonDisabled() {
      return this.isInvalidPassword || this.isSaving;
    },

    /**
     * Retorna o ícone
     * @return {array}
     */
    icon() {
      return this.hasDef(this.icons);
    },

    /**
     * Verifica se a senha atende os seguinte pré requisitos:
     * Mínimo de 8 caracteres
     * Pelo menos 1 número
     * Pelo menos 1 letra
     * O campo "confirmar nova senha" é igual a "nova senha"
     * @return {bool}
     */
    isInvalidPassword() {
      return (
        !this.v$.password.containsLetter.$response ||
        !this.v$.password.containsNumber.$response ||
        !this.v$.password.isValidLength.$response ||
        !this.v$.confirmPassword.sameAsPassword.$response
      );
    },
  },

  validations() {
    const validations = {
      password: {
        required,
        isValidLength,
        containsLetter,
        containsNumber,
      },

      confirmPassword: {
        required,
        sameAsPassword: sameAs(this.password),
      },
    };

    return validations;
  },

  watch: {
    /**
     * Remove o loading da página quando
     * o valor da URL da loja retornar da API
     * @param {string} storeBaseUrl URL base da loja
     * @return {undefined}
     */
    store_info({ store_id }) {
      return this.validateAccessCode(store_id);
    },
  },
};
</script>

<template>
  <section class="app__customer-mail-password-change">
    <header class="app__customer-mail-password-change__header">
      <h3
        class="app__customer-mail-password-change__title--1"
        :class="loadingPage"
      >
        {{ this.langs.password_change.title }}
      </h3>
      <p
        class="app__customer-mail-password-change__title--4"
        :class="loadingPage"
      >
        {{ this.langs.password_change.description }}
      </p>
    </header>

    <form
      class="app__customer-mail-password-change__form"
      @submit.prevent="onSubmit"
    >
      <div class="row justify-content-center">
        <div class="col-12 col-md-9">
          <app-alert :messages="messages" />
        </div>
      </div>

      <div class="row justify-content-center">
        <div class="col-12 col-md-9 position-relative">
          <app-input-group
            identifier="new-password"
            class="newPassword"
            :loading="!!loadingPage"
            :class="loadingPage"
            :value="password"
            :icon="{ prefix: 'fas', name: 'lock' }"
            :label="`${this.langs.password_change['new-password']}`"
            :state="this.getValidatorState(v$.password)"
          >
            <template v-slot:input>
              <input
                id="new-password"
                class="app__input-group__input form-control"
                :type="showPassword ? 'text' : 'password'"
                :placeholder="`${this.langs.password_change['new-password']}`"
                :class="{
                  'app__input-group__input--has-float-label': true,
                  'app__input-group__input--is-empty': !password,
                  app__loading: loadingPage,
                }"
                v-model.trim="password"
                @input="v$.password.$touch()"
              />
            </template>
            <template v-slot:feedbacks>
              <small
                class="app__input-group__feedbacks app__customer-password-change__errors"
              >
                <span
                  v-if="backendErrors.password"
                  class="app__input-group__feedbacks__feedback app__customer-password-change__password_error"
                >
                  {{ backendErrors.password[0] }}
                </span>
              </small>
            </template>
          </app-input-group>

          <button
            class="app__customer-password-change__toggle-visibility"
            :class="loadingPage"
            @click.prevent="toggleVisibility()"
          >
            <app-icon
              class="app__icon"
              prefix="far"
              :name="showPassword ? 'eye' : 'eye-slash'"
            >
            </app-icon>
          </button>
        </div>

        <div class="col-12 col-md-9 position-relative" style="min-height: 64px">
          <app-input-group
            identifier="confirm-password"
            class="passwordConfirmation"
            :class="loadingPage"
            :loading="!!loadingPage"
            :value="confirmPassword"
            :icon="{ prefix: 'fas', name: 'lock' }"
            :label="`${this.langs.password_change['confirm-password']}`"
            :state="this.getValidatorState(v$.confirmPassword)"
          >
            <template v-slot:input>
              <input
                :type="showConfirmation ? 'text' : 'password'"
                :placeholder="`${this.langs.password_change['confirm-password']}`"
                id="confirm-password"
                class="app__input-group__input form-control"
                :class="{
                  'app__input-group__input--has-float-label': true,
                  'app__input-group__input--is-empty': !confirmPassword,
                  app__loading: loadingPage,
                }"
                v-model.trim="confirmPassword"
                @input="v$.confirmPassword.$touch()"
              />
            </template>
            <template v-slot:feedbacks>
              <small
                class="app__input-group__feedbacks app__customer-password-change__errors"
              >
                <span
                  v-if="backendErrors.confirmation"
                  class="app__input-group__feedbacks__feedback"
                >
                  {{ backendErrors.confirmation[0] }}
                </span>
              </small>
              <small
                class="app__input-group__feedbacks app__customer-password-change__errors"
              >
                <span
                  v-if="!v$.confirmPassword.sameAsPassword.$response"
                  class="app__input-group__feedbacks__feedback app__customer-password-change__confirmation_error"
                >
                  {{ this.langs.password_change.errors.equal }}
                </span>
              </small>
            </template>
          </app-input-group>

          <button
            class="app__customer-password-change__toggle-visibility"
            :class="loadingPage"
            @click.prevent="toggleVisibilityConfirmation()"
          >
            <app-icon
              class="app__icon"
              prefix="far"
              :name="showConfirmation ? 'eye' : 'eye-slash'"
            >
            </app-icon>
          </button>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-12 col-md-9">
          <div class="app__customer-password-change__validation-rules">
            <span
              class="app__customer-password-change__validation-rules__contains"
            >
              {{ this.langs.password_change["must-contain"] }}
            </span>
            <span class="app__customer-password-change__validation-rules__list">
              <dl>
                <dt
                  class="app__customer-password-change__validation-rules__list__item"
                  :class="loadingPage"
                >
                  <app-svg
                    :name="
                      !v$.password.isValidLength.$response ? 'times' : 'check'
                    "
                    class="app__icon--rules"
                    :class="loadingPage"
                  >
                  </app-svg>
                  {{ this.langs.password_change["min-characters"] }}
                </dt>
                <dt
                  class="app__customer-password-change__validation-rules__list__item"
                  :class="loadingPage"
                >
                  <app-svg
                    :name="
                      !v$.password.containsNumber.$response ? 'times' : 'check'
                    "
                    class="app__icon--rules"
                    :class="loadingPage"
                  >
                  </app-svg>
                  {{ this.langs.password_change["min-number"] }}
                </dt>
                <dt
                  class="app__customer-password-change__validation-rules__list__item"
                  :class="loadingPage"
                >
                  <app-svg
                    :name="
                      !v$.password.containsLetter.$response ? 'times' : 'check'
                    "
                    class="app__icon--rules"
                    :class="loadingPage"
                  >
                  </app-svg>
                  {{ this.langs.password_change["min-letter"] }}
                </dt>
              </dl>
            </span>
          </div>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-12 col-md-9">
          <button
            id="form-submit-button"
            :disabled="isButtonDisabled"
            :class="[loadingPage, buttonFormClass]"
          >
            {{ this.langs.password_change["save-password"] }}
            <app-icon
              class="app__input-group__icon-sync-alt app__icon--infinite-rotate"
              prefix="fa"
              name="sync-alt"
              v-show="isSaving"
            >
            </app-icon>
          </button>
        </div>
      </div>
      <div class="row justify-content-center" v-show="!this.isSaving">
        <div class="col-12 col-md-9">
          <button
            :class="loadingPage"
            class="app__customer-mail-password-change__back-button"
            @click="this.redirectToStore"
          >
            <app-icon name="angle-down" prefix="fa" />
            <span>{{ this.langs.password_change["back-to-store"] }}</span>
          </button>
        </div>
      </div>
    </form>
  </section>
</template>
